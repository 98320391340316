import React, { Component } from 'react'
// import { Link, graphql } from 'gatsby'
// import { Container } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
// import { withI18next } from '@sckit/gatsby-plugin-i18next'
import { StaticQuery, graphql } from 'gatsby'

class SearchPanel extends Component {
    componentDidMount() {
        if (typeof window !== 'undefined') {
            var _callback = () => {
                if (document.readyState === 'complete') {
                    window.google.search.cse.element.render({
                        div: 'insite_search',
                        tag: 'search',
                        attributes: {
                            resultSetSize: 10
                        }
                    })
                } else {
                    window.google.setOnLoadCallback(function() {
                        window.google.search.cse.element.render({
                            div: 'insite_search',
                            tag: 'search',
                            attributes: {
                                resultSetSize: 10
                            }
                        })
                    }, true)
                }
            }

            window.__gcse = {
                parsetags: 'explicit',
                callback: _callback
            }

            ;(function() {
                // var cx = '012642182385647431975:sj4eb38opqg';
                var cx = document.getElementById("insite_search").getAttribute("data-cseid");
                var gcse = document.createElement('script');
                gcse.type = 'text/javascript';
                gcse.async = true;
                gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(gcse, s);
            })();
        }
    }

    render() {
        return (
            <StaticQuery
            query={graphql`{
                site {
                    siteMetadata {
                        cseId
                    }
                }
            }`}
            render={(data) => {
                return <div id="insite_search" className="mr-sm-2" data-cseId={data.site.siteMetadata.cseId}></div>
            }}
            />
        )
    }
}

export default withNamespaces()(SearchPanel)
